.footer {
    background-color: #D3D3D3;
    color: #1b1c1d;
    position: relative;
    overflow: hidden;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
    position: relative;
    z-index: 2;
  }
  
  .footer-main {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4rem;
  }
  
  .footer-brand {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer-logo {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    background: linear-gradient(45deg, #3b82f6, #ec4899);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .footer-slogan {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .footer-social {
    display: flex;
    gap: 1rem;
  }
  
  .social-icon {
    color: #db95e9;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #3b82f6;
  }
  
  .footer-links-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
  }
  
  .footer-links-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #E75A97;
  }
  
  .footer-links-list {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-link {
    color: #1b1c1d;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #E75A97;
  }
  
  .link-arrow {
    opacity: 0;
    width: 12px;
    height: 12px;
    margin-left: 0.25rem;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .footer-link:hover .link-arrow {
    opacity: 1;
    transform: translate(2px, -2px);
  }
  
  .footer-bottom {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid #2d3748;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-copyright {
    font-size: 0.9rem;
  }
  
  .footer-legal-links {
    display: flex;
    gap: 1.5rem;
  }
  
  .legal-link {
    color: #5e2d56;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  .legal-link:hover {
    color: #E75A97;
  }
  
  .footer-background-pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: 
      radial-gradient(circle at 25px 25px, #2d3748 2%, transparent 0%),
      radial-gradient(circle at 75px 75px, #2d3748 2%, transparent 0%);
    background-size: 100px 100px;
    opacity: 0.1;
    z-index: 1;
  }
  
  @media (max-width: 1024px) {
    .footer-main {
      grid-template-columns: 1fr;
      gap: 3rem;
    }
  
    .footer-links-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 640px) {
    .footer-content {
      padding: 3rem 1.5rem;
    }
  
    .footer-links-grid {
      grid-template-columns: 1fr;
    }
  
    .footer-bottom {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  
    .footer-legal-links {
      flex-direction: column;
      gap: 0.5rem;
    }
  }