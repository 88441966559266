:root {
  --primary-color: #EC4899;
  --secondary-color: #333333;
  --light-color: #fefefe;
  --grey-color: #D3D3D3;
  --grid-size: 10;
}

.home-banner-container {
  position: relative;
  height: calc(100dvh - 60px);
  overflow: hidden;
}

.image-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.banner-image.active {
  opacity: 1;
}

.grid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(var(--grid-size), 1fr);
  grid-template-rows: repeat(var(--grid-size), 1fr);
  background-color: rgba(51, 51, 51, 0.5);
}

.grid-cell {
  border: 1px solid rgba(236, 72, 153, 0.1);
  transition: all 0.3s ease;
}

.grid-cell.active {
  background-color: rgba(236, 72, 153, 0.3);
  box-shadow: 0 0 10px rgba(236, 72, 153, 0.5);
}

.banner-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--light-color);
  z-index: 2;
  width: 90%;
  max-width: 800px;
}

.banner-title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.banner-title span {
  display: block;
}

.banner-subtitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.banner-slogan {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.banner-description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.tech-text {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.tech-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, var(--primary-color), transparent);
  transform: translateX(-100%);
  animation: shine 3s infinite;
}

@keyframes shine {
  100% {
    transform: translateX(100%);
  }
}

.highlight {
  color: var(--primary-color);
}

.cta-button {
  background-color: var(--primary-color);
  color: var(--light-color);
  border: none;
  padding: 12px 24px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #D3367D;
}

.services-carousel {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(51, 51, 51, 0.8);
  overflow: hidden;
}

.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}

.service-card {
  flex: 0 0 auto;
  width: calc(100% / 3);
  max-width: 200px;
  padding: 1rem;
  text-align: center;
  color: var(--light-color);
  transition: all 0.3s ease-in-out;
}

.service-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.service-name {
  font-size: 1rem;
}

@media (max-width: 1024px) {
  .banner-content {
    top: 40%;
  }
  .banner-title {
    font-size: 1.75rem;
    margin-bottom: 0.75rem;
  }

  .banner-subtitle {
    font-size: 1.5rem;
  }

  .banner-slogan {
    font-size: 1.5rem;
  }

  .banner-description {
    font-size: 1.25rem;
  }

  .service-card {
    width: calc(100% / 2);
  }
}

@media (max-width: 768px) {
  .home-banner-container {
    height: calc(100dvh - 48px);
  }

  .banner-title {
    font-size: 2.5rem;
  }

  .banner-subtitle {
    font-size: 1.2rem;
  }

  .banner-slogan {
    font-size: 2rem;
  }

  .banner-description {
    font-size: 1rem;
  }

  .cta-button {
    font-size: 1.1rem;
  }

  .service-card {
    width: 100%;
    padding: 0.8rem;
  }

  .service-icon {
    font-size: 2rem;
  }

  .service-name {
    font-size: 0.9rem;
  }

  :root {
    --grid-size: 8;
  }
}

@media (max-width: 480px) {
  .banner-content {
    padding: 1rem;
  }

  .banner-title {
    font-size: 1.25rem;
  }

  .banner-subtitle {
    font-size: 1rem;
  }

  .banner-slogan {
    font-size: 1.25rem;
  }

  .banner-description {
    font-size: 0.75rem;
  }

  .cta-button {
    font-size: 0.75rem;
    padding: 10px 20px;
  }

  .service-icon {
    font-size: 1.5rem;
  }

  .service-name {
    font-size: 0.75rem;
  }

  :root {
    --grid-size: 5;
  }
}