/* AboutUs.css */
:root {
  --primary: #EC4899;
  --secondary: #333333;
  --light: #fefefe;
  --gray: #D3D3D3;
}

.about-us-container {
  background-color: var(--light);
  width: 100%;
  padding-bottom: 100px;
}

.about-us-content {
  font-family: 'Arial', sans-serif;
  color: var(--secondary);
  background-color: var(--light);
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.intro-section {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.particle-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.intro-content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 2rem;
  background: rgba(51, 51, 51, 0.8);
  border-radius: 20px;
  box-shadow: 0 0 50px rgba(236, 72, 153, 0.3);
}

.company-name {
  font-size: 5rem;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 1rem;
  text-shadow: 0 0 10px rgba(236, 72, 153, 0.5);
}

.tagline-container {
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;
}

.tagline {
  font-size: 2.5rem;
  color: var(--light);
  margin-bottom: 0.5rem;
}

.motto {
  font-size: 1.5rem;
  color: var(--primary);
  margin-bottom: 1rem;
}

.tagline-underline {
  height: 3px;
  background-color: var(--primary);
  width: 0;
  transition: width 1s ease-in-out;
}

.intro-content:hover .tagline-underline {
  width: 100%;
}

.intro-text {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 2rem;
  color: var(--light);
  line-height: 1.6;
}

.tech-icons {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.tech-icon {
  width: 64px;
  height: 64px;
  transition: transform 0.3s ease;
}

.tech-icon:hover {
  transform: scale(1.2);
}

.mission-vision-section {
  display: flex;
  justify-content: space-between;
  margin: 4rem 0;
  gap: 2rem;
}

.mission, .vision {
  flex: 1;
  background: rgba(236, 72, 153, 0.1);
  padding: 2rem;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.mission:hover, .vision:hover {
  transform: translateY(-10px);
}

.mission h3, .vision h3 {
  color: var(--primary);
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2.5rem;
  color: var(--primary);
  margin-bottom: 1.5rem;
}

.core-values-section, .solutions-section, .tech-stack-section, .impact-section {
  margin: 4rem 0;
}

.values-grid, .impact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.value-card, .impact-card {
  background: rgba(225, 225, 225, 0.5);
  border-radius: 15px;
  padding: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.value-card:hover, .impact-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(236, 72, 153, 0.2);
}

.impact-card h3 {
  color: var(--primary);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.solutions-slider {
  position: relative;
  height: 400px; /* Adjust as needed */
  overflow: hidden;
  background: linear-gradient(45deg, rgba(236, 72, 153, 0.1), rgba(51, 51, 51, 0.1));
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.solution-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-content {
  text-align: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  max-width: 80%;
  transition: transform 0.3s ease;
}

.slide-content:hover {
  transform: scale(1.05);
}

.slide-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.slide-content h3 {
  color: var(--primary);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.slide-content p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: var(--primary);
  color: var(--light);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.slider-button:hover {
  background-color: #d63384;
  transform: translateY(-50%) scale(1.1);
}

.slider-button.prev {
  left: 1rem;
}

.slider-button.next {
  right: 1rem;
}

.tech-accordion {
  margin-top: 2rem;
}

.accordion-item {
  margin-bottom: 1rem;
}

.accordion-header {
  width: 100%;
  padding: 1rem;
  background: var(--primary);
  color: var(--light);
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.accordion-header:hover {
  background-color: #d63384;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background: rgba(255, 255, 255, 0.05);
}

.accordion-item.active .accordion-content {
  max-height: 200px;
  padding: 1rem;
}

.cta-section {
  text-align: center;
  background: linear-gradient(45deg, var(--secondary), var(--primary));
  padding: 3rem;
  border-radius: 15px;
  margin-top: 4rem;
}

.cta-section p {
  color: var(--light);
}

.cta-button {
  background-color: var(--light);
  color: var(--secondary);
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 1.5rem;
}

.cta-button:hover {
  background-color: var(--gray);
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .company-name {
    font-size: 3rem;
  }

  .tagline {
    font-size: 1.8rem;
  }

  .motto {
    font-size: 1.2rem;
  }

  .intro-text {
    font-size: 1rem;
  }

  .tech-icon {
    width: 48px;
    height: 48px;
  }

  h2 {
    font-size: 2rem;
  }

  .mission-vision-section {
    flex-direction: column;
  }

  .values-grid, .impact-grid {
    grid-template-columns: 1fr;
  }

  .solutions-slider {
    height: 300px;
  }

  .slide-content {
    padding: 1rem;
  }

  .slide-icon {
    font-size: 3rem;
  }

  .slide-content h3 {
    font-size: 1.5rem;
  }

  .slide-content p {
    font-size: 1rem;
  }

  .slider-button {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }
}

.tech-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(236, 72, 153, 0.3), rgba(51, 51, 51, 0.3));
  z-index: -1;
}

.tech-background::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60"><path d="M15 0 L30 15 L15 30 L0 15 Z" fill="%23EC4899" fill-opacity="0.3"/></svg>');
  animation: move 20s linear infinite;
}

@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(60px, 60px);
  }
}