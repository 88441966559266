:root {
    --header-bg: #ffffff;
    --header-text: #333333;
    --header-hover: #EC4899;
    --header-active: #EC4899;
    --logo-bg: #FEE2E2;
    --logo-text: #333333;
    --logo-highlight: #EC4899;
    --dropdown-bg: #ffffff;
    --dropdown-text: #333333;
    --dropdown-hover: #f7fafc;
    --icon-color: #4a5568;
    --icon-hover: #EC4899;
    --search-bg: #f7fafc;
    --search-text: #333333;
    --search-border: #e2e8f0;
  }
  
  .header {
    background-color: var(--header-bg);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .header-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    padding-right: 47px;
    padding-left: 0;
    /* background-color: #4a5568; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    position: relative;
  }
  
  .left-section {
    display: flex;
    align-items: center;
  }
  
  .logo {
    display: flex;
    align-items: center;
    height: 64px;
  }
  
  .logo-background {
    position: relative;
    background-color: var(--logo-bg);
    padding: 10px 12px;
    clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
    max-width: 200px;
    height: 100%;
  }

  .logo-background img {
    width: 100%;
    height: 100%;
  }
  
  .logo-highlight {
    color: var(--logo-highlight);
  }
  
  .desktop-nav {
    display: flex;
    margin-left: 32px;
  }
  
  .nav-item {
    position: relative;
    margin-right: 17px;
    /* background-color: #333333; */
  }
  
  .nav-link {
    color: var(--header-text);
    text-decoration: none;
    font-size: 16px;
    padding: 20px 0;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  .nav-link-text {
    margin-right: 5px;
  }
  
  .nav-link:hover,
  .nav-link.active {
    color: var(--header-hover);
  }
  
  .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--header-active);
  }
  
  .dropdown-arrow {
    width: 16px;
    height: 16px;
  }
  
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--dropdown-bg);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: none;
    z-index: 1000;
    min-width: 200px;
  }
  
  .dropdown-header {
    height: 4px;
    background-color: var(--header-active);
  }
  
  .nav-item:hover .dropdown {
    display: block;
  }
  
  .dropdown-link {
    display: block;
    padding: 10px 20px;
    color: var(--dropdown-text);
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-link:hover {
    background-color: var(--dropdown-hover);
  }
  
  .right-section {
    display: flex;
    align-items: center;
  }
  
  .search-container {
    position: absolute;
    top: 0;
    left: 135px;
    right: 125px;
    height: 64px;
    background-color: var(--search-bg);
    /* background-color: #4a5568; */
    display: flex;
    align-items: center;
    padding: 0 16px;
    z-index: 1001;
  }
  
  .search-input {
    flex-grow: 1;
    border: none;
    font-size: 16px;
    padding: 8px;
    background-color: transparent;
  }
  
  .search-input:focus {
    outline: none;
  }
  
  .search-submit,
  .search-close {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    color: var(--icon-color);
    transition: color 0.3s ease;
    /* background-color: #333333; */
  }
  
  .icon-button:hover {
    color: var(--icon-hover);
  }
  
  .icon {
    width: 24px;
    height: 24px;
  }
  
  .mobile-menu-button,
  .mobile-search-button {
    display: none;
  }

      /* ---------- MEDIA QUERIES -------------- */
    /* ---------------------------------------- */

  
  @media (max-width: 768px) {
    .header-container {
      height: 48px;
      padding: 0 16px;
    }
  
    .left-section {
      flex-grow: 1;
      justify-content: flex-start;
    }
  
    .desktop-nav {
      display: none;
    }
  
    .mobile-left {
      display: flex;
      align-items: center;
    }
  
    .mobile-menu-button,
    .mobile-search-button {
      display: flex;
      padding: 0;
      margin: 0;
    }
  
    .mobile-menu-button {
      margin-right: 6px;
    }
  
    .logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 48px;
    }
  
    .logo-background {
      padding: 8px;
      background-color: transparent;
    }

  
    .right-section {
      display: flex;
      align-items: center;
    }
  
    .icon-button {
      margin-left: 16px;
    }
  
    .icon {
      width: 20px;
      height: 20px;
    }
  
    .search-container.mobile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 48px;
      background-color: var(--search-bg);
      display: flex;
      align-items: center;
      padding: 0 16px;
      z-index: 1001;
    }
  
    .search-input {
      flex-grow: 1;
      border: none;
      font-size: 16px;
      padding: 8px 40px 8px 8px;
      background-color: transparent;
    }
  
    .search-submit {
      position: absolute;
      right: 48px;
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
    }
  
    .search-close {
      position: absolute;
      right: 16px;
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
    }
  
    .mobile-nav {
      position: fixed;
      top: 48px;
      left: 0;
      right: 0;
      background-color: var(--header-bg);
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      padding: 16px;
      z-index: 999;
      max-height: calc(100vh - 48px);
      overflow-y: auto;
    }
  
    .mobile-nav-item {
      margin-bottom: 16px;
    }
  
    .mobile-nav-link {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--header-text);
      text-decoration: none;
    }
  
    .mobile-dropdown {
      margin-left: 20px;
      margin-top: 8px;
    }
  
    .mobile-dropdown-link {
      display: block;
      padding: 8px 0;
      color: var(--dropdown-text);
      text-decoration: none;
      font-size: 14px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .desktop-nav {
      margin-left: 20px;
    }
    .nav-link-text {
      margin-right: 1px;
    }

    .logo-background {
      width: 115px;
      height: 100%;
    }
  }

