@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family:  'Roboto', "Poppins", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* fonts */
  --headings-headings-h4: Inter;
  --font-roboto: Roboto;
  --font-source-sans-pro: "Source Sans Pro";

  /* font sizes */
  --font-size-base: 16px;
  --font-size-xs: 12px;
  --font-size-mini-1: 14.1px;
  --font-size-4xl-4: 23.4px;
  --font-size-lgi: 19px;
  --font-size-smi-9: 12.9px;
  --font-size-12xl-4: 31.4px;
  --font-size-6xl: 25px;
  --font-size-mid-3: 17.3px;
  --font-size-22xl: 41px;
  --font-size-14xl: 33px;
  --paragraphs-default-size: 14px;
  --headings-headings-h4-size: 20px;
  --headings-headings-h2-size: 35px;
  --font-size-2xl: 21px;
  --font-size-9xl: 28px;
  --font-size-base-4: 16.4px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --gray-gray-50: #fafafa;
  --color-gray-100: #1f1f39;
  --gray-gray-900: #1a202c;
  --color-gray-200: rgba(31, 31, 57, 0.7);
  --color-gray-300: rgba(39, 39, 46, 0);
  --color-gray-400: rgba(255, 255, 255, 0.5);
  --color-silver: #bcbec0;
  --color-whitesmoke-100: #f7f7fa;
  --color-whitesmoke-200: #efefef;
  --color-whitesmoke-300: #e3ebe2;
  --color-dimgray-100: #666;
  --color-dimgray-200: #585858;
  --gray-gray-700: #4a5568;
  --color-dimgray-300: #4f4f4f;
  --gradient-primary-bg: #f76680;
  --color-plum: #ea96c7;
  --color-lavenderblush-100: #f8f0f4;
  --color-lavenderblush-200: #fbe7f2;
  --color-palevioletred: #b86394;
  --shade-primary-lite-bg: #e8daed;
  --color-gainsboro: #d9d9d9;
  --gray-gray-600: #718096;
  --gray-gray-800: #2d3748;

  /* Gaps */
  --gap-12xs-1: 0.1px;
  --gap-xl: 20px;
  --gap-mid-6: 17.6px;
  --gap-30xl-8: 49.8px;
  --gap-6xl: 25px;
  --gap-base-2: 15.2px;
  --gap-mini-1: 14.1px;
  --gap-mid: 17px;
  --gap-13xl-2: 32.2px;
  --gap-base: 16px;
  --gap-3xl-8: 22.8px;
  --gap-11xl-4: 30.4px;
  --gap-mini: 15px;
  --gap-99xl: 118px;
  --gap-10xl: 29px;
  --gap-40xl: 59px;
  --gap-32xl-2: 51.2px;
  --gap-7xl: 26px;
  --gap-4xl: 23px;
  --gap-71xl: 90px;
  --gap-3xl: 22px;
  --gap-26xl: 45px;
  --gap-mini-8: 14.8px;
  --gap-5xl: 24px;
  --gap-6xl-6: 25.6px;
  --gap-3xs: 10px;
  --gap-5xl-3: 24.3px;
  --gap-69xl: 88px;
  --gap-25xl: 44px;
  --gap-21xl-1: 40.1px;
  --gap-5xs-6: 7.6px;
  --gap-mini-6: 14.6px;
  --gap-10xl-3: 29.3px;

  /* Paddings */
  --padding-10xl-3: 29.3px;
  --padding-133xl: 152px;
  --padding-xl: 20px;
  --padding-57xl: 76px;
  --padding-9xs-3: 3.3px;
  --padding-9xs-7: 3.7px;
  --padding-8xs: 5px;
  --padding-3xs: 10px;
  --padding-mid: 17px;
  --padding-39xl-6: 58.6px;
  --padding-127xl: 146px;
  --padding-39xl-5: 58.5px;
  --padding-19xl: 38px;
  --padding-17xl: 36px;
  --padding-54xl: 73px;
  --padding-3xl-8: 22.8px;
  --padding-97xl: 116px;
  --padding-449xl: 468px;
  --padding-96xl: 115px;
  --padding-51xl: 70px;
  --padding-56xl: 75px;
  --padding-16xl: 35px;
  --padding-215xl: 234px;
  --padding-12xs-1: 0.1px;
  --padding-xs-7: 11.7px;
  --padding-xs: 12px;
  --padding-11xs: 2px;
  --padding-7xs: 6px;
  --padding-mini: 15px;
  --padding-59xl-4: 78.4px;
  --padding-4xl: 23px;
  --padding-4xl-4: 23.4px;
  --padding-7xs-3: 5.3px;
  --padding-4xs: 9px;
  --padding-7xs-4: 5.4px;
  --padding-lgi: 19px;
  --padding-6xs: 7px;
  --padding-190xl-8: 209.8px;
  --padding-117xl: 136px;
  --padding-3xl: 22px;
  --padding-2xl: 21px;
  --padding-5xs: 8px;
  --padding-12xs: 1px;
  --padding-53xl-4: 72.4px;
  --padding-77xl-6: 96.6px;
  --padding-15xl: 34px;
  --padding-12xl: 31px;
  --padding-22xl: 41px;
  --padding-28xl: 47px;
  --padding-44xl: 63px;
  --padding-40xl: 59px;
  --padding-9xl: 28px;
  --padding-20xl: 39px;
  --padding-10xl: 29px;
  --padding-148xl: 167px;
  --padding-64xl: 83px;
  --padding-37xl-6: 56.6px;
  --padding-37xl-4: 56.4px;
  --padding-18xl: 37px;
  --padding-51xl-3: 70.3px;
  --padding-27xl: 46px;

  /* Border radiuses */
  --br-8xs-4: 4.4px;
  --br-xs: 12px;
  --br-mid-6: 17.6px;
  --br-8xs-7: 4.7px;
  --br-3xs: 10px;
  --br-7xs: 6px;
  --br-6xs: 7px;

  /* Effects */
  --card-shadow-lite: 0px 4px 30px rgba(0, 0, 0, 0.05);
  --card-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}
