:root {
    --primary-color: #EC4899;
    --secondary-color: #333333;
    --light-color: #fefefe;
    --grey-color: #D3D3D3;
  }
  
  .company-info-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Poppins', sans-serif;
    color: var(--secondary-color);
    padding-bottom: 150px;
    padding-top: 150px;
  }
  
  .carousel-news-section {
    display: flex;
    gap: 2rem;
    margin-bottom: 3rem;
  }
  
  .carousel {
    flex: 2;
    position: relative;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .carousel-item.active {
    opacity: 1;
    animation: fadeIn 0.5s ease-out;
  }
  
  .carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .carousel-item h3 {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: var(--light-color);
    background: rgba(236, 72, 153, 0.8);
    padding: 10px 20px;
    margin: 0;
    border-radius: 5px;
  }
  
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(236, 72, 153, 0.8);
    color: var(--light-color);
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  
  .carousel-control:hover {
    background: var(--primary-color);
    transform: translateY(-50%) scale(1.1);
  }
  
  .carousel-control.left { left: 10px; }
  .carousel-control.right { right: 10px; }
  
  .carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
  }
  
  .indicator {
    width: 10px;
    height: 10px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transition: background 0.3s ease;
  }
  
  .indicator.active {
    background: var(--light-color);
  }
  
  .recent-news {
    flex: 1;
    background: var(--light-color);
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .recent-news h2 {
    margin-top: 0;
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 10px;
  }
  
  .recent-news ul {
    list-style-type: none;
    padding: 0;
  }
  
  .recent-news li {
    margin-bottom: 20px;
  }
  
  .news-title {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
    font-size: 1.1em;
    transition: color 0.3s ease;
  }
  
  .news-title:hover {
    color: var(--secondary-color);
    text-decoration: underline;
  }
  
  .news-abstract {
    color: var(--secondary-color);
    font-size: 0.9em;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .news-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  
  .news-pagination button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--primary-color);
    transition: all 0.3s ease;
  }
  
  .news-pagination button:hover {
    transform: scale(1.1);
  }
  
  .about-us {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 3rem;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    padding: 3rem;
    border-radius: 10px;
    color: var(--light-color);
  }
  
  .about-us-content {
    flex: 1;
    animation: slideInLeft 0.5s ease-out;
  }
  
  .about-us h2 {
    font-size: 2.5em;
    margin-bottom: 1rem;
  }
  
  .about-us p {
    font-size: 1.1em;
    line-height: 1.6;
  }
  
  .about-us-image {
    flex: 1;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    animation: slideInRight 0.5s ease-out;
  }
  
  .about-us-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .our-approach h2 {
    text-align: center;
    color: var(--primary-color);
    font-size: 2.5em;
    margin-bottom: 2rem;
  }
  
  .approach-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .approach-card {
    background: var(--light-color);
    padding: 2rem;
    border-radius: 10px;
    transition: all 0.3s ease;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-out;
  }
  
  .approach-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
  
  .card-icon {
    font-size: 3em;
    color: var(--primary-color);
    margin-bottom: 1rem;
    transition: transform 0.3s ease;
  }
  
  .approach-card:hover .card-icon {
    transform: scale(1.1);
  }
  
  .approach-card h3 {
    color: var(--secondary-color);
    margin-bottom: 1rem;
  }
  
  .approach-card p {
    color: var(--secondary-color);
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideInLeft {
    from { transform: translateX(-50px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  @keyframes slideInRight {
    from { transform: translateX(50px); opacity: 0; }
    to { transform: translateX(0); opacity: 1; }
  }
  
  @media (max-width: 1024px) {
    .company-info-container {
      padding: 1rem;
    padding-bottom: 100px;
    padding-top: 100px;
    }
  
    .carousel-news-section {
      flex-direction: column;
    }
  
    .carousel {
      height: 350px;
    }
  
    .recent-news {
      margin-top: 2rem;
    }
  
    .approach-cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .carousel {
      height: 250px;
    }
  
    .about-us {
      flex-direction: column;
      padding: 2rem;
    }
  
    .about-us-image {
      order: -1;
      margin-bottom: 1rem;
    }
  
    .approach-cards {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 480px) {
    .carousel {
      height: 200px;
    }
  
    .carousel-item h3 {
      font-size: 1rem;
      padding: 5px 10px;
    }
  
    .news-title {
      font-size: 1em;
    }
  
    .news-abstract {
      font-size: 0.8em;
    }
  
    .about-us h2 {
      font-size: 2em;
    }
  
    .about-us p {
      font-size: 1em;
    }
  
    .our-approach h2 {
      font-size: 2em;
    }
  
    .approach-card {
      padding: 1.5rem;
    }
  
    .card-icon {
      font-size: 2.5em;
    }
  }